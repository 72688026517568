.wrapper{
  display: flex;
  flex-direction: column;
}

header{
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
}

header .logoRow {
  display: flex;
	flex-direction: row;
}

header .logoRow span{
  flex-shrink: 1;
  flex-grow: 0;
}

header .logoRow .flexspacer{
  flex-grow: 1;
  min-width: 1em;
}

main {
  display: block;
  flex-shrink: 0;
  flex-grow: 1;
}

footer {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}
