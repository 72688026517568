$maxWidth: 850px;

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
		width: 100%;
    overflow-x: hidden;
}

img, embed, object,video {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.wrapper{
	width:100%;
	height: 100%;
}

header{
	width: 100%;
}
header .logoRow {
	max-width: $maxWidth;
  margin:auto;
  padding: 0.2em 0 0.2em 0;
}
header .navRow{
	width: 100%;
	border-top-style: solid;
	border-top-width: 0.75em;
}
header nav {
	max-width: $maxWidth;
	margin:auto;
}

main {
  display:block;
  width: 100%;
}

article {
  max-width: $maxWidth;
	margin:auto;
  padding: 0 1em 0 1em;
  border-width: 0 3px 0 3px;
  border-style: dashed;
}

section {
  display:block;
  border-width: 4px;
  border-style: solid;
  padding: 0.5em;
  margin: 0 0 1.5em 0;
  border-radius: 0.75em;
}

section .legend{
  padding: 0 0.5em 0 0.5em;
  margin: 0 0 -1em 0;
  display: inline-block;
  float:left;
  position: relative;
  top: -1.25em;
  border: 0px solid;
  border-radius: 0.75em;
}

section .imgRight{
  margin: 0.8em;
  float:right;
  max-width: 45%;
}

aside {
  display:block;
  padding: 1em;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5em;
  margin: 0.5em 0 0.5em 0.5em;
  /*max-width: 40%;*/
  /*float:right;*/
}

address {
  padding-left: 1em;
  font-style: normal;
}

footer{
	width: 100%;
}

footer .footerRow{
  max-width: $maxWidth;
	height: 4em;
  padding: 0 1em 0 1em;
  margin:auto;
}

.infoIcon{
  float:left;
  padding-right: 0.8em;
}
li { 
  padding-top: 0.7em;
}