nav ul {
   list-style-type: none;
   margin: 0;
   padding: 0;
   overflow: hidden;
}

nav li {
   float: left;
   border-right-style: solid;
   border-right-width: 1px;
}

nav li:last-child {
	 border-right: none;
}

nav li a {
   display: block;
   text-align: center;
   padding: 0.75em 1em 0.75em 1em;
}

nav li.dropdown {
    display: block;
}

nav .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
}

nav .dropdown-content a {
    padding: 0.75em 1em 0.75em 1em;
    text-decoration: none;
    display: block;
    text-align: left;
}

nav .dropdown:hover .dropdown-content {
    display: block;
}
