body {
	font-size: 18px;
	line-height: 1.4em;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

h1 {
line-height: 1.4em;
font-size: 2em;
}

.upperCase {
	text-transform:uppercase;
}

nav .dropDownButton span{
	font-weight: bold;
}

footer p{
	font-size: 60%;
}
figcaption {
	font-style: italic;
}

blockquote {
	font-style: italic; 
}